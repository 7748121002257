angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.dashboard', {
            url: '/',
            views: {
                "main": {
                    controller: 'dashboardCtrl',
                    templateUrl: 'web/main/dashboard/dashboard.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Dashboard'
            }
        });
    })
    .controller('dashboardCtrl', function ($rootScope, $scope, session, $window, userService, $timeout, fingerinkSession, signatureService,idCardModal) {

        var that = this;
        that.loading = true;
        that.user = session.get().user;

        that.session = session.get();
        if (that.session.plan && that.session.plan.serviceStates && that.session.plan.serviceStates[0]) {
            that.serviceStates = that.session.plan.serviceStates[0];
        }
        that.credits = session.getRemainingCredits();

       //$timeout(()=> idCardModal.openModal('dfdf'),500);
        
        /*if (that.user.firstTime) {
            $timeout(function () {
                introJs().start();
                that.user.firstTime = false;
                userService.updateUsuario(that.user).then(() => fingerinkSession.renoveToken());
            }, 1500);
        }*/

        var params = { emisor: that.user.id, /*estado: 'COMPLETED',*/ limit: 3 };
        signatureService.retrieveFirmas(params).then(response => { that.documents = response.data; that.loading = false; });

    });
